<header class="p-2 bg-gray-100">
    <div class="w-full flex justify-between items-center">
        <!-- Logo and QBG text -->
        <div
            (click)="goToHomePage()"
            class="flex items-center cursor-pointer mb-4 ml-3 md:mb-0 space-x-2"
        >
            <img
                src="../../../assets/home/logo pw.svg"
                alt="logo"
                class="h-8"
            />
            <span class="heading-4 font-bold">QBG</span>
        </div>

        <!-- User Details -->
        <div class="flex items-center space-x-2 md:mr-5" *ngIf="userInfo">
            <h4 class="body-2 font-semibold">
                {{ userInfo?.firstName + ' ' + userInfo?.lastName || '' }}
            </h4>
            <div
                class="flex items-center justify-center cursor-pointer"
                [matMenuTriggerFor]="menu"
            >
                <img
                    [src]="
                        userInfo?.imageId?.baseUrl + userInfo?.imageId?.key ||
                        'assets/profile/smile/smile.png'
                    "
                    alt="User Avatar"
                    class="w-10 h-10 rounded-full"
                />
            </div>
            <mat-icon class="cursor-pointer" [matMenuTriggerFor]="menu"
                >arrow_drop_down</mat-icon
            >
        </div>

        <!-- Mat Menu for Logout -->
        <mat-menu #menu="matMenu" class="mat-menu">
            <span
                (click)="handleLogout()"
                class="body-2 font-medium"
                mat-menu-item
                >Logout</span
            >
        </mat-menu>
    </div>
</header>
