<mat-dialog-content>
    <div class="flex flex-wrap mx-2 overflow-hidden">
        <div
            class="my-2 px-2 w-full overflow-hidden spinner-div vertical-align"
        >
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>

        <div class="my-2 px-2 w-full overflow-hidden text-center">
            <p *ngIf="message && message.length > 0" class="text-center">
                {{ message }}
            </p>
            <p *ngIf="message && message.length === 0" class="text-center">
                Getting data...
            </p>
        </div>
    </div>
</mat-dialog-content>
