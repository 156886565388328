import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class StorageRefService {
    localStorage: Storage | null = null;

    constructor(@Inject(PLATFORM_ID) private platformId: any) {}

    getLocalStorage(): Storage {
        if (!this.localStorage && isPlatformBrowser(this.platformId)) {
            this.localStorage = window.localStorage;
        }
        return this.localStorage as Storage;
    }
}
