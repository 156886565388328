<ng-container
    *ngIf="
        (isUserLoggedIn$ | async) &&
        !router.url.includes(authRouted.AUTH) &&
        !router.url.includes(authRouted.FORGOT_PASSWORD) &&
        !router.url.includes(authRouted.NOTIFICATION) &&
        !router.url.includes(authRouted.RESET_PASSWORD) &&
        !router.url.includes(authRouted.OTP_VERIFY) &&
        !router.url.includes(authRouted.PRINT_PREVIEW) &&
        !router.url.includes(authRouted.ACADEMY_ADMIN) &&
        !router.url.includes(dppRedirectionPages.CREATE_QUESTION_PAPER) &&
        router.url?.split('?')?.[0] !==
            '/' + dppRedirectionPages.FINALIZE_QUESTION_PAPER &&
        !isUserCameFromAcademyAdmin
    "
>
    <app-main-header></app-main-header>
</ng-container>

<router-outlet></router-outlet>
