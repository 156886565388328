import { environment } from 'environments/environment';
import tinymce from 'tinymce';

import {
    CLIENT_TYPE,
    editorEnabledOrigins,
} from '../services/global-constant/global-constant.service';
import { HTML_IMG_TAG_REMOVAL_PATTERN } from './regex';

export const getS3SecuredUrl = (file: any) => {
    return `${
        environment.apiConfig.baseUrl
    }core-utilities/files/s3-signed-url?type=QBG&name=${
        file?.name
    }&extension=${(file.name || '').split('.').pop()}&contentType=${file?.type}`;
};

const baseToolbar =
    'undo redo | styleselect | bold italic | link image | code | preview | numlist bullist';

/* TinyMCE editor Config */
export const EDITOR_CONFIG = {
    base_url: '/tinymce', // Root for resources
    height: 200,
    suffix: '.min', // Suffix to use when loading resources
    paste_data_images: false,
    paste_block_drop: true,

    // Add wiris plugin
    external_plugins: isWirisEditorEnabled()
        ? {
              tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
          }
        : {},

    htmlAllowedTags: ['.*'],
    htmlAllowedAttrs: ['.*'],
    // This option allows us to introduce mathml formulas
    extended_valid_elements: '*[.*]',

    // We recommend to set 'draggable_modal' to true to avoid overlapping issues
    // with the different UI modal dialog windows implementations between core and third-party plugins on TinyMCE.
    // @see: https://github.com/wiris/html-integrations/issues/134#issuecomment-905448642
    draggable_modal: true,
    plugins: [
        'image',
        'preview',
        'paste',
        'code',
        'help',
        'wordcount',
        'imagetools',
        'autoresize',
        'lists',
        'table',
    ],
    table_toolbar:
        'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',

    toolbar: isWirisEditorEnabled()
        ? `${baseToolbar} | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry`
        : baseToolbar,

    paste_preprocess: (editor: any, args: any) => {
        args.content = args.content.replace(HTML_IMG_TAG_REMOVAL_PATTERN, '');
    },

    /* enable title field in the Image dialog*/
    image_title: true,
    /* enable automatic uploads of images represented by blob or data URIs*/
    automatic_uploads: true,

    file_picker_types: 'image',

    /* and here's our custom image picker*/
    file_picker_callback: function (callback: any, value: any, meta: any) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        /*
        Note: In modern browsers input[type="file"] is functional without
        even adding it to the DOM, but that might not be the case in some older
        or quirky browsers like IE, so you might want to add it to the DOM
        just in case, and visually hide it. And do not forget do remove it
        once you do not need it anymore.
        */

        if (meta.filetype === 'image') {
            input.click();

            input.onchange = function () {
                const file = input?.files && input.files[0];

                const reader = new FileReader();
                if (file) {
                    reader.onload = function () {
                        /*
                        Note: Now we need to register the blob in TinyMCEs image blob
                        registry. In the next release this part hopefully won't be
                        necessary, as we are looking to handle it internally.
                        */
                        const id = 'blobid' + new Date().getTime();
                        const blobCache =
                            tinymce.activeEditor?.editorUpload.blobCache;
                        if (typeof reader.result === 'string') {
                            const url: string = getS3SecuredUrl(file);
                            const xhttp = new XMLHttpRequest();
                            xhttp.open('GET', url, true);

                            const formData = new FormData();
                            const token = localStorage.getItem('TOKEN');
                            formData.append('file', file);
                            const user = localStorage.getItem('user');
                            let userId: string = '';
                            if (user) {
                                const userObj = JSON.parse(user);
                                userId = userObj.id;
                            }

                            try {
                                xhttp.setRequestHeader('Accept', '*!/!*');
                                xhttp.setRequestHeader(
                                    'Authorization',
                                    'Bearer ' +
                                        localStorage
                                            .getItem('TOKEN')
                                            ?.toString()!
                                );

                                xhttp.setRequestHeader('user-id', `${userId}`);
                                xhttp.setRequestHeader(
                                    'organization-id',
                                    `${environment.pwOrgId}`
                                );
                                xhttp.setRequestHeader(
                                    'Client-Type',
                                    `${CLIENT_TYPE}`
                                );

                                xhttp.send(formData);
                                // @ts-ignore
                                document.getElementsByClassName(
                                    'tox-dialog__footer-end'
                                    // @ts-ignore
                                )?.[0]?.childNodes?.[1]?.disabled = true;
                                xhttp.onreadystatechange = async function (e) {
                                    // @ts-ignore
                                    document.getElementsByClassName(
                                        'tox-dialog__footer-end'
                                        // @ts-ignore
                                    )?.[0]?.childNodes?.[1]?.disabled = false;
                                    if (this.readyState === 4) {
                                        if (this.status === 200) {
                                            const response = JSON.parse(
                                                this.responseText
                                            ).data;
                                            const uploadResponse =
                                                await uploadSignedURLJSON(
                                                    response,
                                                    file
                                                );

                                            if (uploadResponse?.ok) {
                                                response['fullUrl'] =
                                                    response.baseUrl +
                                                    response.key;
                                            }

                                            const blobInfo = blobCache!.create(
                                                id,
                                                file,
                                                response.baseUrl + response.key
                                            );
                                            blobCache!.add(blobInfo);

                                            callback(
                                                response.baseUrl + response.key,
                                                {
                                                    title: response.name,
                                                }
                                            );
                                        } else {
                                            const error = JSON.parse(
                                                this.responseText
                                            ).error;
                                            const errorMessage = JSON.parse(
                                                this.responseText
                                            ).message;

                                            if (
                                                errorMessage === 'Unauthorized'
                                            ) {
                                                let event = new CustomEvent(
                                                    'USER_UNAUTHORIZED',
                                                    {
                                                        detail: errorMessage,
                                                    }
                                                );
                                                window.dispatchEvent(event);
                                            }
                                            callback('', {
                                                alt:
                                                    'Error: ' + error.message ||
                                                    'There were some error',
                                            });
                                        }
                                    }
                                };
                            } catch (e) {
                                alert(token + '' + e);
                            }
                        }
                    };
                    reader.readAsDataURL(file);
                }
            };
        }
    },

    // Handle events.
    setup(editor: any) {
        // Launch on init event.
        editor.on('init', () => {
            // Get and set the editor and wiris versions in this order.
            // Generic.setEditorAndWirisVersion(`${(window as any).tinymce.majorVersion}.${(window as any).tinymce.minorVersion}`, (window as any).WirisPlugin.currentInstance.version);   //eslint-disable-line
        });
    },
    content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
};

export async function uploadSignedURLJSON(data: any, file: File): Promise<any> {
    return await fetch(data?.uploadUrl, {
        method: 'PUT',
        body: file,
        headers: {
            'Content-Type': file.type,
        },
    });
}

/**To check if the current origin is in the wiris editor origin list **/
export function isWirisEditorEnabled(): boolean {
    return editorEnabledOrigins.includes(window.location.hostname);
}
