import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global/global.service';
import { StorageService } from '../../services/storage/storage.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {
    userInfo: any;
    userSubs: Subscription | undefined;
    constructor(
        private router: Router,
        private _globalService: GlobalService,
        private storageService: StorageService
    ) {
        this.getUserProfile();
    }

    ngOnInit(): void {}

    goToHomePage() {
        this.storageService.removeUploadedQuestionData();
        this.router.navigate(['home']);
    }

    getUserProfile() {
        this.userSubs = this._globalService.getUser().subscribe((user) => {
            if (user) {
                this.userInfo = user;
            }
        });
    }

    async handleLogout() {
        this._globalService.setAccessToken('');
        this._globalService.removeUser();
        this._globalService.authFailure();
        this.storageService.clearAllLocalStorage();
        await this.router.navigate(['/auth'], {
            queryParams: {
                /* url: window.location.pathname, */
                url: '',
            },
        });
    }
}
