import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { finalize, tap } from 'rxjs/operators';
import { AfterLoginService } from '../after-login/after-login.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../global/global.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { CLIENT_TYPE } from '../global-constant/global-constant.service';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
    random_id: string = '';
    userSubs!: Subscription;
    userInfo: any;
    headerObj: { [key: string]: string } = {};
    userRole: any;

    constructor(
        private injector: Injector,
        private _afterLoginService: AfterLoginService,
        private activatedRoute: ActivatedRoute,
        private _globalService: GlobalService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let storageService = this.injector.get(StorageService);

        if (storageService.getUserToken() !== null) {
            this.userRole = JSON.parse(
                storageService.getValue('QBG_USER_ROLE', 'string')
            );

            this.userSubs = this._globalService.getUser().subscribe((user) => {
                if (user) {
                    this.userInfo = user;
                    const headers: { [key: string]: string } = {
                        roleId: this.userRole?.id || '',
                        user: this.userRole?.name || '',
                        'user-id': this.userInfo.id || '',
                        'organization-id': environment.pwOrgId || '',
                        'user-name':
                            (this.userInfo?.firstName || '') +
                            ' ' +
                            (this.userInfo?.lastName || ''),
                        'Client-Type': CLIENT_TYPE,
                    };

                    /** Handling override of headers. Only add headers if they are not already present in req */
                    this.headerObj = Object.keys(headers).reduce(
                        (
                            resultHeaders: { [key: string]: string },
                            key: string
                        ) => {
                            if (!req.headers.has(key)) {
                                resultHeaders[key] = headers[key];
                            }
                            return resultHeaders;
                        },
                        {}
                    );
                }
            });
        }

        this.random_id =
            this.activatedRoute.snapshot.queryParamMap.get('random_id') ||
            this._globalService.getRandomId();
        const started = Date.now();
        let ok: string;
        let tokenized = req.clone({
            setHeaders: {
                Authorization: `Bearer ${storageService.getUserToken()}`,
                randomId: this.random_id,
                ...(storageService.getUserToken() !== null
                    ? this.headerObj
                    : {}),
            },
        });

        return next.handle(tokenized).pipe(
            tap(
                (event) =>
                    (ok = event instanceof HttpResponse ? 'succeeded' : ''),
                (error) => {
                    ok = error.status + 'failed';
                    if (error.status === 401) {
                        this.logoutUser();
                    }
                }
            ),
            finalize(() => {
                const elapsed = Date.now() - started;
                const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
            })
        );
    }

    logoutUser() {
        this._globalService.showSnackBar(
            'You have been logged out! Please Re-login.'
        );
        this._afterLoginService.handleLogout();
    }
}
