import { environment } from 'environments/environment';

const CDN_BASE_URL = `${environment.staticCdnUrl}5b09189f7285894d9130ccd0/`;

export const CLIENT_TYPE = 'QBG';

export const ADMIN_CLIENT_TYPE = 'ADMIN';

export enum ROLE_MAPPING {
    QBG_ADMIN = 'qbg admin',
    SME = 'sme',
    QC = 'qc',
    DEMAND = 'demand',
    DTP_FREELANCER = 'dtp freelancer',
    GOOGLE_AUTH_QBG = 'google auth qbg',
    QBG_DC = 'qbg dc',
    TAG_MANAGER = 'tag manager',
    QBG_SUB_ADMIN = 'qbg sub admin',
}

export const ALLOWED_ROLES_FOR_BASKET = [
    ROLE_MAPPING.QBG_ADMIN,
    ROLE_MAPPING.QBG_DC,
    ROLE_MAPPING.QBG_SUB_ADMIN,
    ROLE_MAPPING.DEMAND,
];

export enum ADMIN_ROLE_MAPPING {
    ADMIN = 'admin',
    TEACHER = 'teacher',
    INSTITUTE_HEAD = 'institute head',
    INSTITUTE_TEACHER = 'institute teacher',
    SUPER_ADMIN = 'academy super admin',
    ADMIN_ACADEMY = 'academy admin',
}

export enum AUTH_TYPE {
    PASSWORD = 'password_based',
    OTP = 'otp_based',
}

export enum COUNTRY_CODES {
    INDIA = '+91',
}

export enum QUESTION_TYPES {
    SINGLE_CHOICE = 1,
    MULTIPLE_CHOICE = 2,
    NUMERICAL = 3,
    TRUE_FALSE = 4,
    SUBJECTIVE = 5,
    FILL_IN_BLANKS = 6,
    ASSERTION_REASON = 7,
    COMPREHENSION = 8,
    MATCHING_LIST = 9,
    CASE_STUDY = 10,
    TYPE_IN_THE_ANSWER = 11,
}

export enum QuestionTypesNames {
    SingleChoiceTypeQuestion = 'Single Choice (SCQ)',
    MultipleChoiceTypeQuestion = 'Multi Choice (MCQ)',
    NumericalTypeQuestion = 'Numerical',
    TrueFalseTypeQuestion = 'True or False (TF)',
    SubjectiveTypeQuestion = 'Subjective (SUB)',
    FillInBlanksTypeQuestion = 'Fill in Blanks (FIB)',
    AssertionReasonTypeQuestion = 'Assertion Reason (AR)',
    ComprehensionTypeQuestion = 'Comprehension (COMP)',
    MatchingListTypeQuestion = 'Matching List (ML)',
    CaseStudyTypeQuestion = 'Case Study (CS)',
    TypeInTheAnswerTypeQuestion = 'Type in the Answer (TITA)',
}

export const QuestionTypeWordSampleFile = {
    [QuestionTypesNames.SingleChoiceTypeQuestion]:
        CDN_BASE_URL + 'q8fpar9bob3h6tzl0csxmudvk.docx',
    [QuestionTypesNames.MultipleChoiceTypeQuestion]:
        CDN_BASE_URL + 'hn650tbk9sniqrso5ragg1rgf.docx',
    [QuestionTypesNames.NumericalTypeQuestion]:
        CDN_BASE_URL + '0ggp5jxcttgl0ac2fh1vsmokw.docx',
    [QuestionTypesNames.TrueFalseTypeQuestion]:
        CDN_BASE_URL + 'c1u2icrh0qc4r6n9vxlie5pnt.docx',
    [QuestionTypesNames.SubjectiveTypeQuestion]:
        CDN_BASE_URL + 'zs26dlf9cd1pjndxvk3hi12o7.docx',
    [QuestionTypesNames.FillInBlanksTypeQuestion]:
        CDN_BASE_URL + 'x65abl5avofe3d3zb8qn13t5u.docx',
    [QuestionTypesNames.AssertionReasonTypeQuestion]:
        CDN_BASE_URL + 'jn936bklerhf8y1eueciv7xpa.docx',
    [QuestionTypesNames.ComprehensionTypeQuestion]:
        CDN_BASE_URL + 'y7e69oggtej5l86l3wzvifqtf.docx',
    [QuestionTypesNames.MatchingListTypeQuestion]:
        CDN_BASE_URL + '4rygqceky6dmaipqp23fxqng2.docx',
    [QuestionTypesNames.CaseStudyTypeQuestion]:
        CDN_BASE_URL + 'pc9k3el4k456ow5qd4i1nwmdp.docx',
    [QuestionTypesNames.TypeInTheAnswerTypeQuestion]:
        CDN_BASE_URL + 'bdurqkyit5qaqlf7o04hlm7h9.docx',
};

export enum CHILD_QUESTION_TYPES_NAMES {
    SINGLE_CHOICE_TYPE_QUESTION = 'SCQ',
    MULTIPLE_CHOICE_TYPE_QUESTION = 'MCQ',
    NUMERICAL_TYPE_QUESTION = 'NUMERICAL',
    TRUE_FALSE_TYPE_QUESTION = 'TF',
    SUBJECTIVE_TYPE_QUESTION = 'SUB',
    FILL_IN_BLANKS_TYPE_QUESTION = 'FIB',
    ASSERTION_REASON_TYPE_QUESTION = 'AR',
    MATCHING_LIST_TYPE_QUESTION = 'ML',
    TYPE_IN_THE_ANSWER_TYPE_QUESTION = 'TITA',
}

export enum ExamCategories {
    GATE = 'GATE',
    SSC = 'SSC',
    Railway = 'Railway',
    Banking = 'Banking',
    TET = 'TET',
    CA = 'CA',
    Commerce = 'Commerce',
    CAT = 'CAT',
    Defence = 'Defence',
    NEET_PG = 'NEET PG',
    UPSC = 'UPSC',
}

export enum AUTH_ROUTED {
    AUTH = '/auth',
    FORGOT_PASSWORD = '/forgot-password',
    NOTIFICATION = '/notification',
    RESET_PASSWORD = '/reset-password',
    OTP_VERIFY = '/otp-verify',
    ACADEMY_ADMIN = 'ACADEMY_ADMIN',
    PRINT_PREVIEW = '/finalize-question-paper/print-preview',
}

export enum PROCESSING_TYPE {
    FILE_PROCESSED = 'FILE_PROCESSED',
}

export enum TAGS {
    NOT_PARAPHRASED = 'Not Paraphrased',
    IMPORTANT = 'Important',
    TRICKY = 'Tricky',
    DEBATABLE = 'Debatable',
    QUIZ_READY = 'Quiz Up Ready',
    SEO_FRIENDLY = 'SEO Friendly',
    DPP = 'DPP',
    INFINITE_PRACTICE = 'Infinite Practice',
    IMAGE_BASED = 'Image Based',
    CLINICAL_SCENARIO = 'Clinical Scenario',
    PATIENT_HISTORY = 'Patient History',
    INVESTIGATION_DIAGNOSIS = 'Investigations and Diagnosis',
    MANAGEMENT = 'Management',
    FORMULA_PROBLEMS = 'Formula and Problems',
    CLINICAL_EXAMINATION = 'Clinical Examination',
    ONE_LINER = 'One Liner',
    TEST = 'Test',
    QBANK = 'QBank',
    GRAND_TEST = 'Grand Test (GT)',
    SPECIAL_TEST = 'Special Test (ST)',
    SUBJECT_WISE_TEST = 'Subject Wise Test (SWT)',
    MOCK_TEST = 'Mock Test (MT)',
    NSAT = 'NSAT',
    AITS = 'AITS',
    OT = 'OT',
    ISAT = 'ISAT',
}

/*source enum for previous year question*/
export enum SOURCE_TYPE {
    PYQ = 'PYQ',
    DPP_MODULES = 'dpp modules',
}

export enum DifficultyLevelValue {
    Easy = 1,
    Medium = 2,
    Hard = 3,
}

export enum DifficultyName {
    Easy = 'Easy',
    Medium = 'Medium',
    Hard = 'Hard',
}

export const DifficultyNameValue = {
    Easy: 0,
    Medium: 1,
    Hard: 2,
};

export enum VERIFICATION_STATUS {
    VERIFY = 'verify',
    REJECT = 'reject',
    ACCEPT = 'accept',
    UN_VERIFY = 'unverify',
    DEFAULT_STATE = 0,
    VERIFIED_STATE = 1,
    PUBLISHED_STATE = 3,
    REJECT_STATE = 4,
}

export enum VerificationStatus {
    Draft = 'Draft',
    Verified = 'Verified',
    Rejected = 'Rejected',
}

export const VerificationStatusValue = {
    Draft: 0,
    Verified: 1,
    Rejected: 4,
};

export enum DEFAULT_LANGUAGE {
    ENGLISH = 'English',
}

export enum SNACKBAR_MESSAGE {
    CORRECT_OPTION_CANNOT_EMPTY = 'Selected correct option cannot be empty. Please correct.',
    OPTION_COUNT_IN_DIFFERENT_LANGUAGE = 'Cannot have different options count for different language forms. Please correct it.',
    OPTION_CHECK_IN_DIFFERENT_LANGUAGE = 'Cannot have different correct options for different language forms. Please correct it.',
    OPTION_COMPARISON_TRUE_FALSE = 'Cannot have different selected option in T/F Question Type for different language forms. Please correct it.',
    COMPREHENSION_CHILD_QUESTION_COMPARISON = 'Cannot have different Comprehension Child Data (child question count, selected option, question type) for different language forms. Please correct it.',
    INTERNAL_SERVER_ERROR = 'Internal server error',
    QUESTION_UPDATED_MESSAGE = 'Question updated successfully.',
    CORRECT_VIDEO_SOLUTION = 'Please enter correct video solution.',
    QUESTION_DELETE_MESSAGE = 'Question is deleted successfully.',
    REQUIRED_FIELDS = 'Please fill the required fields of question.',
    VERIFY_MESSAGE = 'Please verify question.',
    EITHER_TEXT_OR_VIDEO_SOLUTION_REQUIRED = ' Please add either text solution or video solution (URL).',
    ADD_ATLEAST_ONE_OPTION = ' Please add atleast one correct option.',
    SETTING_ENGLISH_DEFAULT_LANG = 'No language conversion was found for your selected language. The system will now switch to the default language, which is English',
    CATEGORY_ADDED = 'Category successfully added.',
    CATEGORY_UPDATED = 'Category successfully updated.',
    ADDED_SUCCESSFULLY = 'added successfully.',
    CHANGED_SUCCESSFULLY = 'changed successfully.',
    UPLOAD_SUCCESSFULLY = 'upload successfully.',
    DELETED_SUCCESSFULLY = 'deleted successfully.',
    UPDATED_SUCCESSFULLY = 'updated successfully.',
    REQUEST_MADE_SUCCESSFULLY = 'Request successfully placed.',
    DPP_EXPORTED = 'QBank exported successfully.',
    OPTION_REQUIRED = 'Please select a correct option.',
    ORDER_CHANGED = 'Order changed successfully.',
    DUPLICATE_RULE = 'Duplicate rule generated successfully.',
    QUESTION_REMOVE_MESSAGE = 'Question is removed successfully.',
}

export enum TEST_MODES {
    DPP = 'dpp',
    TEST = 'test',
}

export enum GENERAL_MESSAGE {
    EXAM_CATEGORY_EMPTY = 'Please select relevant exam category to add question.',
}

export enum FORM_TYPE {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export enum CUSTOM_VALIDATION {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
}

export enum MODAL_TYPE {
    DIALOG = 'dialog',
}

export enum CONCEPT_TAGS_ENUM {
    CATEGORY_CONFIGURATION = 'category-configuration',
    EXAM_CATEGORY = 'Exam Category',
    CATEGORY_ID = 'category_id',
}

export enum ToBeChangedType {
    Class,
    Subject,
    Chapter,
    Topic,
    Subtopic,
    Stream,
    Section,
    AcademicYear,
    Unit,
    Category,
}
export enum RequestType {
    Addition,
    Updation,
    Deletion,
}
export enum RequestStatus {
    Pending,
    Approved,
    Rejected,
}

export enum RequestStatusValue {
    Approved = 'approved',
    Rejected = 'rejected',
}

export const PanelTabs = {
    tabs: [
        {
            title: 'Add/Modify',
            value: '',
            key: 'category',
        },
        {
            title: 'Action Pending',
            value: '0',
            key: 'draft',
        },
        {
            title: 'Approved',
            value: '1',
            key: 'approved',
        },
        {
            title: 'Rejected',
            value: '2',
            key: 'rejected',
        },
        {
            title: 'My Requests',
            value: '',
            key: 'requests',
        },
    ],
};

export enum RequestQuestionStatus {
    Rejected,
    Pending,
    Completed,
}

export enum PaginationMovement {
    Previous = 'previous',
    Next = 'next',
}

export enum IsFormFieldAdded {
    AUTOMATIC = 'automatic',
    MANUALLY = 'manually',
}

export enum SEARCH_TYPE {
    TEXT = 'text',
    IDS = 'ids',
    IMAGE = 'image',
}

export enum CREATE_DPP_CHOICE {
    DPP = 'dpp',
    SESSION_DPP = 'session_dpp',
}

export enum DPP_REDIRECTION {
    CREATE_QUESTION_PAPER = 'create-question-paper',
    MAKE_QUESTION_PAPER = 'make-question-paper',
    FINALIZE_QUESTION_PAPER = 'finalize-question-paper',
}

export enum TeacherActionTypeOverDpp {
    Approve = 'approve',
    Reject = 'reject',
}

export enum EXPORT_DPP_CHOICE {
    DOWNLOAD = 'download',
    UPLOAD = 'upload',
}

export enum CRUD {
    ADD = 'add',
    EDIT = 'edit',
    REMOVE = 'remove',
    DELETE = 'delete',
}

export enum SOLUTION_TYPE {
    TEXT = 1,
    VIDEO = 2,
}

export enum ANSWER_TYPE {
    INTEGER = 1,
}

export enum SOLUTION_TYPE_VALUE {
    TEXT = 'Text',
    VIDEO = 'Video',
}

export enum ANSWER_TYPE_VALUE {
    INTEGER = 'Integer Only',
}

export const SavedQuestionPaperPanelTabs = {
    tabs: [
        {
            title: 'Ongoing',
            value: '',
            key: 'ongoing',
        },
        {
            title: 'Completed',
            value: '0',
            key: 'completed',
        },
    ],
};

export enum UserTypeStatus {
    DppCreator,
    DppApprove,
}

export enum DppListingStatus {
    Ongoing,
    Completed,
}

export enum DPP_STATUS {
    In_progress,
    Pending,
    Approved,
    Rejected,
}

export enum DPP_STATUS_VALUE {
    IN_PROGRESS = 'In Progress',
    PENDING = 'Pending',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
}
export enum DppExportStatus {
    Not_Exported,
    Exported,
}

export enum TAG_TYPE {
    X_CATEGORY = 'X Category',
}

export enum DPP_OPTION_TYPE {
    ALPHABETICAL = 'alphabetical',
    NUMERICAL = 'numerical',
}

export enum IFRAME_MESSAGE_EVENT {
    IFRAME_LOADED = 'IFRAME_LOADED',
    IFRAME_DATA = 'IFRAME_DATA',
}

export const TestModes = {
    Online: 'Online',
    Offline: 'Offline',
    HomeBased: 'HomeBased',
};

export enum TaskStatus {
    NotProcessed,
    UnderProcessing,
    Processed,
    Draft,
    Library,
    Completed,
    Failed,
}

export const VideoTypes = {
    SECURE_VIDEO: 'penpencilvdo',
    YOU_TUBE_VIDEO: 'youtube',
};

export const TagsVisibility = {
    ALL: 'all',
    VISIBLE: 'visible',
};

export const VideoStatus = {
    READY: 'ready',
    PENDING: 'pending',
    PROCESSING: 'processing',
    ERROR: 'error',
};

export const AllowedLanguages = {
    eng: 'english',
    hin: 'hindi',
    mar: 'marathi',
    guj: 'gujarati',
    kan: 'kannada',
    ben: 'bengali',
    tel: 'telugu',
    und: 'undefined',
};

/* Wiris mathtype editor enabled origins */
export const editorEnabledOrigins = [
    'qbg.physicswallah.live',
    'localhost',
    'qbg-admin.penpencil.co',
    'qbg-admin-pre-prod.penpencil.co',
    'qbg-admin-stage.penpencil.co',
    'qbg-admin-dev.penpencil.co',
];

export const ALLOWED_ROLES_FOR_QUESTION_DELETION = [ROLE_MAPPING.QBG_ADMIN];

export const EXPORT_TYPE = {
    ALL: 'all',
    SELECTED: 'selected',
};

export const IframeQueryParams = {
    AUTHORIZATION_TOKEN: 'authToken',
    IS_IFRAME_MODE_ENABLED: 'isIframeModeEnabled',
    IS_TEST_MODE_ACTIVE: 'isTestModeActive',
    SOURCE_APPLICATION: 'sourceApp',
    EXERCISE_ID: 'exerciseId',
    SUBJECT_ID: 'subjectId',
    CHAPTER_ID: 'chapterId',
    TOPIC_ID: 'topicId',
    CONTENT_ID: 'contentId',
    SECTION_ID: 'sectionId',
    INSTITUTE_ID: 'instituteId',
};
