import { Component, HostListener, OnInit } from '@angular/core';
import {
    NavigationStart,
    Router,
    Event,
    ActivatedRoute,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './common/services/global/global.service';
import {
    ALLOWED_ROLES_FOR_BASKET,
    AUTH_ROUTED,
    DPP_REDIRECTION,
} from './common/services/global-constant/global-constant.service';
import { AuthService } from './common/services/auth/auth.service';
import { AfterLoginService } from './common/services/after-login/after-login.service';
import { StorageService } from './common/services/storage/storage.service';
import { HelperService } from './common/services/helper/helper.service';
import { isWirisEditorEnabled } from './common/configurations/editor-config';

declare var trackUserRouting: any;
declare var getCrashesFromConsole: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title: string = '';
    isUserLoggedIn$: Observable<boolean>;
    router: Router;
    authRouted = AUTH_ROUTED;
    dppRedirectionPages = DPP_REDIRECTION;
    userInfo: any;
    getUser$: any;
    getUserToken$: any;
    isUserCameFromAcademyAdmin: boolean = false;

    constructor(
        private _globalService: GlobalService,
        private _router: Router,
        private _authService: AuthService,
        private _afterLoginService: AfterLoginService,
        private activatedRoute: ActivatedRoute,
        private _storageService: StorageService,
        private _helperService: HelperService
    ) {
        this.isUserLoggedIn$ = this._globalService.getIsLoggedIn();
        this.getUser$ = this._globalService.getUser();
        this.getUserToken$ = this._globalService.getAccessToken();
        this.router = this._router;
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                if (event?.url === '/home') {
                    this._storageService.removeSelectedQuestionData();
                    this._storageService.removeConceptTagActiveTab();
                    this._storageService.removeDppStatusActiveTab();
                    this._storageService.removeExamCategory();
                    this._storageService.removeExportPdfData();
                    this._storageService.removeExportWordData();
                    this._storageService.removeQuestionIds();
                } else if (event?.url.includes('isShared')) {
                    this.shareBasket();
                } else if (
                    !event?.url.includes('preview-pdf') &&
                    !event?.url.includes('print-preview')
                ) {
                    this._storageService.removeExportPdfData();
                }
            }
            /*if (event instanceof NavigationStart) {
                this.detectRouteChange(event.url);
            }*/
        });
        setTimeout(() => {
            this.isTokenExist();
        }, 500);
    }

    @HostListener('window:USER_UNAUTHORIZED', ['$event'])
    onUserUnauthorized(event: any): void {
        if (event && event?.detail === 'Unauthorized') {
            this.logoutUser();
        }
    }

    ngOnInit() {
        setTimeout(() => {
            this.isUserCameFromAcademyAdmin =
                this._helperService.isUserCameFromAcademyAdmin();
        }, 1000);
        if (isWirisEditorEnabled()) {
            this.loadWirisScript();
        }
    }

    detectRouteChange(routeUrl: any) {
        if (this.getUserToken$?.value) {
            let data = {
                routeUrl: routeUrl,
                userId: this.getUser$?.value?.id,
                token: this.getUserToken$?.value,
            };
            trackUserRouting(data);
            /*getCrashesFromConsole();*/
        }
    }

    isTokenExist() {
        const accessToken =
            this.activatedRoute.snapshot.queryParamMap.get('TOKEN') ||
            this._globalService.getAccessToken().getValue() ||
            '';
        if (accessToken.length > 0) {
            this.self();
        }
    }

    async self() {
        this._authService.self().subscribe(
            (res) => {
                if (res && res['data']) {
                    this.userInfo = res['data'];
                    if (window.location.pathname === '/auth') {
                        this.router.navigate(['/home']);
                    }
                }
            },
            (err) => {
                if (
                    err &&
                    err.error &&
                    (err.error.status === 401 || err.error.status === 403)
                ) {
                    this.logoutUser();
                }
            }
        );
    }

    logoutUser() {
        this._globalService.showSnackBar(
            'You have been logged out! Please Re-login.'
        );
        this._afterLoginService.handleLogout();
    }

    //This function is used for sharing basket externally to , so that allowed roles may create basket through it
    shareBasket() {
        const urlParams = new URLSearchParams(window.location.search);
        const user_id = urlParams.get('user_id');
        const basket_id = urlParams.get('basket_id');
        const roleName =
            JSON.parse(this._storageService.getQbgUserRole() ?? '')?.name || '';
        const isAllowedToAccess = ALLOWED_ROLES_FOR_BASKET.includes(
            roleName?.toString().toLowerCase()
        );
        if (isAllowedToAccess && this.getUser$?.value?.id !== user_id) {
            this.router.navigate(['saved-question-paper'], {
                queryParams: {
                    sharedBasket: true,
                    basket_id: basket_id,
                },
            });
        } else if (!isAllowedToAccess) {
            this.router.navigate(['/home']);
        }
    }

    loadWirisScript() {
        /* Load WIRIS plugins.js dynamically */
        const jsDemoImagesTransform = document.createElement('script');
        jsDemoImagesTransform.type = 'text/javascript';
        jsDemoImagesTransform.src =
            'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';

        /* Append the script to the document head */
        document.head.appendChild(jsDemoImagesTransform);
    }
}
