import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PpHttpOptions, PpHttpParams, PpHttpService } from '@penpencil/common';
import { environment } from 'environments/environment';
import {
    BehaviorSubject,
    catchError,
    debounceTime,
    map,
    Observable,
    retry,
} from 'rxjs';

import { handleError } from '../error-handler/error-handler.service';
import {
    AUTH_TYPE,
    CLIENT_TYPE,
} from '../global-constant/global-constant.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    _authenticated = new BehaviorSubject<boolean>(false);

    constructor(
        private apiService: PpHttpService,
        private storageService: StorageService
    ) {
        if (
            storageService.getUser() &&
            storageService.getUserToken() &&
            storageService.getExpiryDate()
        ) {
            this._authenticated.next(true);
        } else {
            this._authenticated.next(false);
        }
    }

    self(query?: any) {
        const options: PpHttpOptions = {
            apiPath: 'v1/admin/users/self',
        };
        return this.apiService
            .get<any>(options)
            .pipe(retry(0), debounceTime(1000), catchError(handleError));
    }

    googleAuthSignIn(data: any): Observable<any> {
        const options: PpHttpOptions = {
            apiPath: 'v3/admin/oauth/social-connect',
        };

        return this.apiService.post(data, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    signOut(): void {
        // Remove the access token from the local storage
        this.storageService.removeUser();
        this.storageService.removeUserToken();
        this.storageService.removeExpiryDate();
        // Set the authenticated flag to false
        this._authenticated.next(false);
    }

    sendOtp(user: any, smsType: number): Observable<any> {
        const otpDetails: any = {
            username: user?.username,
            countryCode: user?.countryCode,
            organizationId: environment.pwOrgId,
        };

        const options: PpHttpOptions = {
            apiPath: 'v1/admin/users/get-otp',
            headers: new HttpHeaders().append('Client-Type', CLIENT_TYPE),
            params: new PpHttpParams().append('smsType', smsType),
        };
        return this.apiService
            .post(otpDetails, options)
            .pipe(retry(1), debounceTime(1000), catchError(handleError));
    }

    resendOtp(user: any, smsType: number): Observable<any> {
        const otpDetails: any = {
            mobile: user?.username,
            organizationId: environment.pwOrgId,
        };

        const options: PpHttpOptions = {
            apiPath: 'v1/admin/users/resend-otp',
            headers: new HttpHeaders().append('Client-Type', CLIENT_TYPE),
            params: new PpHttpParams().append('smsType', smsType),
        };
        return this.apiService
            .post(otpDetails, options)
            .pipe(debounceTime(1000), catchError(handleError));
    }

    login(user: any, authType: string) {
        const loginDetails: any = {
            username: user.mobile,
            client_id: 'system-admin',
            client_secret: 'KjPXuAVfC5xbmgreETNMaL7z',
            grant_type: 'password',
            organizationId: environment.pwOrgId,
            latitude: user.latitude || 0,
            longitude: user.longitude || 0,
        };

        if (authType === AUTH_TYPE.PASSWORD) {
            loginDetails['password'] = user?.password;
        } else {
            loginDetails['otp'] = user?.otp;
            loginDetails['ttl'] = 604800000;
        }

        const options: PpHttpOptions = {
            apiPath: 'v1/admin/oauth/user/token',
        };
        return this.apiService.post<any>(loginDetails, options).pipe(
            debounceTime(1000),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    forgotPassword(user: any) {
        const userDetails = {
            username: user.username,
        };

        const options: PpHttpOptions = {
            apiPath: 'v1/admin/users/forget-password',
            headers: new HttpHeaders().append('Client-Type', CLIENT_TYPE),
        };
        return this.apiService.post<any>(userDetails, options).pipe(
            retry(2),
            debounceTime(1000),
            catchError(handleError),
            map((res: any) => res)
        );
    }

    resetPassword(resetData: any) {
        const userDetails = {
            otp: resetData.otp,
            otpNumber: resetData.otpNumber,
            password: resetData.password,
            username: resetData.username,
        };

        const options: PpHttpOptions = {
            apiPath: 'v1/admin/users/reset-password',
            headers: new HttpHeaders().append('Client-Type', CLIENT_TYPE),
        };
        return this.apiService.post<any>(userDetails, options).pipe(
            retry(2),
            debounceTime(1000),
            catchError(handleError),
            map((res: any) => res)
        );
    }
}
