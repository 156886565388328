import { Injectable } from '@angular/core';
import {
    PpHttpHeaders,
    PpHttpOptions,
    PpHttpParams,
    PpHttpService,
} from '@penpencil/common';
import { BehaviorSubject, catchError, map, retry } from 'rxjs';

import { handleError } from '../error-handler/error-handler.service';
import {
    CreateDppModal,
    FileDownloadRequest,
} from '../questions/question.model';
import { StorageService } from '../storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class QuestionPaperService {
    constructor(
        public apiService: PpHttpService,
        private storageService: StorageService
    ) {}
    _processedZipFileCount$: BehaviorSubject<any> = new BehaviorSubject({});

    startSession() {
        const options = {
            apiPath: 'qbg/dpp-test-sessions/start-session',
        };
        return this.apiService.post<any>('', options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createCustomRule(session_id: string, body: any) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/create-ruleset`,
            params: new PpHttpParams().appendAll({ is_custom_rule: true }),
        };
        return this.apiService.put<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    deleteRuleSet(session_id: string, ruleId: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/delete-rule/${ruleId}`,
        };
        return this.apiService.delete<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSessionDetails(session_id: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/get-session-details`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    updateSession(body: any, session_id: string) {
        let sendData = body;
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/update-session`,
        };
        return this.apiService.put<any>(sendData, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    endSession() {
        const data = {};
        const options = {
            apiPath: 'qbg/dpp-test-sessions/end-session',
        };
        return this.apiService.put<any>(data, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    clearSession(session_id: string) {
        const data = {};
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/clear-session`,
        };
        return this.apiService.put<any>(data, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createRule(body: any, session_id: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/create-ruleset`,
        };
        return this.apiService.put<any>({ rule: body }, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    editRule(body: any, rule_id: any, session_id: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/update-rule/${rule_id}`,
        };
        return this.apiService.put<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    duplicateRule(body: any, rule_id: any, session_id: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/duplicate-rule/${rule_id}`,
        };
        return this.apiService.put<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    deleteRule(data: any, session_id: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/delete-rule/${data}`,
        };
        return this.apiService.delete<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    executeRule(session_id: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/execute-rules`,
        };
        return this.apiService.put<any>('', options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getPaperRuleSet(query: any) {
        const options = {
            apiPath: `qbg/question-paper/${query.paperId}/rules`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    replaceQuestion(data: any, session_id: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/replace-question/${data.ruleId}/${data.questionId}`,
        };
        return this.apiService.put<any>('', options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    reportQuestion(data: any) {
        const options = {
            apiPath: 'qbg/report-questions',
        };
        return this.apiService.post<any>(data, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getCountPotentialQues(body: any) {
        const options = {
            apiPath: 'qbg/question-paper/potential-count',
        };
        return this.apiService.post<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getPredefinedPatterns() {
        const options = {
            apiPath: 'qbg/question-paper/patterns/predefined',
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createQPDpp(body: any, basket_id: string, query?: any) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/export-basket`,
            params: query ? new PpHttpParams().appendAll(query) : undefined,
        };
        return this.apiService
            .post<CreateDppModal>(body, options)
            .pipe(catchError(handleError));
    }

    fileProcessed(request_id: string) {
        const options = {
            apiPath: `qbg/requests/${request_id}`,
        };
        return this.apiService.get<FileDownloadRequest>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createQPTest(body: any) {
        const options = {
            apiPath: 'qbg/question-paper/test',
        };
        return this.apiService.post<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getQuestionPaper(query: any) {
        const options = {
            apiPath: `qbg/question-paper/${query.paperId}`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    replacePotentialQues(query: any) {
        const options = {
            apiPath: `qbg/question-paper/${query.questionId}/replace`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getQuestionQP(query: any) {
        const options = {
            apiPath: `qbg/question-paper/${query.paperId}/questions`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    setQuestionQP(body: any, query: any) {
        const options = {
            apiPath: `qbg/question-paper/dpp/${query.paperId}/questions`,
        };
        return this.apiService.post<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    addQuestionsTest(body: any, query: any) {
        const options = {
            apiPath: `qbg/question-paper/test/${query.paperId}/questions`,
        };
        return this.apiService.post<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getPotentialQuestionQP(query: any) {
        const options = {
            apiPath: `qbg/question-paper/${query.paperId}/potential-questions`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    updateTitleQP(body: any, query: any) {
        const options = {
            apiPath: `qbg/question-paper/${query.paperId}/title`,
        };
        return this.apiService.put<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getQPlist() {
        const options = {
            apiPath: 'qbg/question-paper/list/all',
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    removeQuestion(session_id: string, questionId: any, ruleId: string) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/remove-question/${questionId}`,
            params: new PpHttpParams().append('rule_id', ruleId),
        };
        return this.apiService.put<any>('', options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getQuestionPaperStatusData(query?: any) {
        const userRoleId = this.storageService.getAcademyUserRoleId();
        const options: PpHttpOptions = {
            apiPath: 'qbg/basket',
            params: new PpHttpParams().appendAll(query),
            headers: userRoleId
                ? new PpHttpHeaders().append('academy-role-id', `${userRoleId}`)
                : new PpHttpHeaders(),
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res)
        );
    }

    getQuestionPaperStatusDataForAcademyRoles(query?: any) {
        const userRoleId = this.storageService.getAcademyUserRoleId();
        const options: PpHttpOptions = {
            apiPath: 'qbg/dpp-test-sessions/role-wise-session',
            params: new PpHttpParams().appendAll(query),
            headers: new PpHttpHeaders().append(
                'academy-role-id',
                `${userRoleId}`
            ),
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res)
        );
    }

    deleteQuestionPaper(basket_id: string, userRoleId?: string, query?: any) {
        const options = {
            apiPath: `qbg/basket/${basket_id}`,
            params: new PpHttpParams().appendAll(query),
            headers: userRoleId
                ? new PpHttpHeaders().append('academy-role-id', `${userRoleId}`)
                : new PpHttpHeaders(),
        };
        return this.apiService.delete<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    deleteQuestionPaperAcademyRoleWise(
        session_id: string,
        instituteId: string,
        userRoleId: string
    ) {
        const query: any = { instituteId: instituteId };
        const options: PpHttpOptions = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/role-wise-delete-dpp-session`,
            params: new PpHttpParams().appendAll(query),
            headers: new PpHttpHeaders().append(
                'academy-role-id',
                `${userRoleId}`
            ),
        };
        return this.apiService.delete<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    recreateQuestionPaper(basket_id: string, body: any) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/share-basket`,
        };
        return this.apiService.post<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    setSelectedQuestion(session_id: string, body: any) {
        const options = {
            apiPath: `qbg/dpp-test-sessions/${session_id}/get-selected-questions`,
        };
        return this.apiService.post<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createBasket(payload: any) {
        const options = {
            apiPath: 'qbg/basket',
        };
        return this.apiService.post<any>(payload, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getBasket(basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    updateBasket(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}`,
        };
        return this.apiService.put<any>(body, options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    createBasketRule(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    updateBasketRule(body: any, rule_id: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/${rule_id}`,
        };
        return this.apiService.put<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    deleteBasketRule(rule_id: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/${rule_id}`,
        };
        return this.apiService.delete<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getBasketRule(rule_id: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/${rule_id}`,
        };
        return this.apiService.get<any>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    removeBasketRuleQuestion(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/remove-questions`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    replaceBasketRuleQuestion(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/replace-question`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getTextSearchBasketRule(basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/rule/text-search-rule`,
        };
        return this.apiService.get<any>(options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    resetBasket(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/reset-basket`,
        };
        return this.apiService.post<any>(body, options).pipe(
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    exportBasketExercise(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/qbg-pp/basket/${basket_id}/export-basket-exercise`,
        };
        return this.apiService
            .post<any>(body, options)
            .pipe(catchError(handleError));
    }

    exportBasketTest(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/export-basket-test`,
        };
        return this.apiService
            .post<any>(body, options)
            .pipe(catchError(handleError));
    }

    exportBasket(body: any, basket_id: string) {
        const options = {
            apiPath: `qbg/basket/${basket_id}/export-basket`,
        };
        return this.apiService
            .post<any>(body, options)
            .pipe(catchError(handleError));
    }
}
