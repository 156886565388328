import { Injectable } from '@angular/core';
import { PpHttpOptions, PpHttpParams, PpHttpService } from '@penpencil/common';
import { catchError, map, retry } from 'rxjs';

import { handleError } from '../error-handler/error-handler.service';
import { QUESTION_TYPES } from '../global-constant/global-constant.service';
import {
    AcademicYear,
    ChaptersByCategory,
    ExamCategoryModel,
    LanguageTab,
    ListingModel,
    Section,
    SourceListByExamCategory,
    Stream,
    SubjectByCategory,
    TargetExamCategory,
    TopicSubTopicByCategory,
    UnitName,
    xCategoryModal,
} from './lisiting.modal';
import { StorageService } from '../../../common/services/storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class ListingService {
    constructor(
        public apiService: PpHttpService,
        public _storageService: StorageService
    ) {}

    questionTypeWithOptions = [
        QUESTION_TYPES.SINGLE_CHOICE,
        QUESTION_TYPES.MULTIPLE_CHOICE,
        QUESTION_TYPES.FILL_IN_BLANKS,
        QUESTION_TYPES.ASSERTION_REASON,
        QUESTION_TYPES.MATCHING_LIST,
    ];
    questionTypeWithoutOptions = [
        QUESTION_TYPES.NUMERICAL,
        QUESTION_TYPES.TRUE_FALSE,
        QUESTION_TYPES.SUBJECTIVE,
        QUESTION_TYPES.TYPE_IN_THE_ANSWER,
    ];

    getSubjects() {
        const options: PpHttpOptions = {
            apiPath: 'qbg/subjects',
        };
        return this.apiService.get<Array<ListingModel>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSubject(query: any) {
        const options: PpHttpOptions = {
            apiPath: 'qbg/class-subject',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Array<ListingModel>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getClasses(query?: any) {
        const options = {
            apiPath: 'qbg/classes',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<ListingModel>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getChapters(query: any) {
        const options = {
            apiPath: 'qbg/chapters',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Array<ListingModel>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getTopics(query: any) {
        const options = {
            apiPath: 'qbg/topics',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Array<ListingModel>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSubTopics(query: any) {
        const options = {
            apiPath: 'qbg/subtopics',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Array<ListingModel>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getAllContent(query: any) {
        const options = {
            apiPath: 'qbg/contents',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Array<any>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getAllExams() {
        const options = {
            apiPath: 'qbg/target-exam',
        };
        return this.apiService.get<Array<any>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSources() {
        const options = {
            apiPath: 'qbg/questions/sources',
        };
        return this.apiService.get<Array<any>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getBookSources(query: any) {
        const options = {
            apiPath: 'qbg/book-sources',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Array<any>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getExamCategory(isStatus?: Boolean, page?: number, search?: string) {
        const instituteId = this._storageService.getInstituteID();
        let query: any = {
            status: isStatus ? 'All' : 'visible',
        };
        if (page) {
            query = {
                ...query,
                page: page,
                ...(search && { search: search }),
                limit: 20,
            };
        }
        query = {
            ...query,
            ...(instituteId && { instituteId: instituteId }),
        };
        const options: PpHttpOptions = {
            apiPath: 'qbg/category-configuration',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Array<ExamCategoryModel>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getExamCategoryDetails(id: string) {
        const options: PpHttpOptions = {
            apiPath: `qbg/category-configuration/${id}`,
        };
        return this.apiService.get<Array<ExamCategoryModel>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getLanguages() {
        const options: PpHttpOptions = {
            apiPath: 'qbg/languages',
        };
        return this.apiService.get<Array<LanguageTab>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSourcesOnExamCategory(query: any) {
        const options: PpHttpOptions = {
            apiPath: 'qbg/book-sources',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService
            .get<Array<SourceListByExamCategory>>(options)
            .pipe(
                retry(1),
                catchError(handleError),
                map((res: any) => res['data'])
            );
    }

    getXCategoryByCategory(category_id: string) {
        const options: PpHttpOptions = {
            apiPath: `qbg/x-category-tags-mapping/${category_id}`,
        };
        return this.apiService.get<Array<xCategoryModal>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getExamDetails(id: any) {
        const options: PpHttpOptions = {
            apiPath: `qbg/target-exam/${id}`,
        };
        return this.apiService
            .get<Array<SourceListByExamCategory>>(options)
            .pipe(
                retry(1),
                catchError(handleError),
                map((res: any) => res['data'])
            );
    }

    getSourceDetails(id: any) {
        const options: PpHttpOptions = {
            apiPath: `qbg/book-sources/${id}`,
        };
        return this.apiService
            .get<Array<SourceListByExamCategory>>(options)
            .pipe(
                retry(1),
                catchError(handleError),
                map((res: any) => res['data'])
            );
    }

    getTargetExamCategory(query: any) {
        const options = {
            apiPath: 'qbg/target-exam',
            params: new PpHttpParams().appendAll(query),
        };

        return this.apiService.get<Array<TargetExamCategory>>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getStreams(query?: any) {
        const options = {
            apiPath: 'qbg/streams',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Stream>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSectionByStream(query: any) {
        const options = {
            apiPath: 'qbg/sections',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<Section>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSubjectsByCategory(query: any) {
        const options = {
            apiPath: 'qbg/subjects',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<SubjectByCategory>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getChaptersByCategory(query: any) {
        const options = {
            apiPath: 'qbg/chapters',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<ChaptersByCategory>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getTopicByCategory(query: any) {
        const options = {
            apiPath: 'qbg/topics',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<TopicSubTopicByCategory>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getSubTopicByCategory(query: any) {
        const options = {
            apiPath: 'qbg/subtopics',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<TopicSubTopicByCategory>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getUnitByCategory(query: any) {
        const options = {
            apiPath: 'qbg/units',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<UnitName>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }

    getAcademicYear(query?: any) {
        const options = {
            apiPath: 'qbg/academic-years',
            params: new PpHttpParams().appendAll(query),
        };
        return this.apiService.get<AcademicYear>(options).pipe(
            retry(1),
            catchError(handleError),
            map((res: any) => res['data'])
        );
    }
}
