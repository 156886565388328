import { PpHttpConfig } from '@penpencil/common';
import {
    BASE_URLs,
    ENVIRONMENT_VARIABLES_PRODUCTION,
} from './environment.shared';

const apiConfig: PpHttpConfig = {
    baseUrl: BASE_URLs.production,
};

export const environment = {
    production: true,
    apiConfig,
    ...ENVIRONMENT_VARIABLES_PRODUCTION,
};
