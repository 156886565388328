import { Injectable } from '@angular/core';
import { IframeQueryParams } from '../global-constant/global-constant.service';

@Injectable({
    providedIn: 'root',
})
export class QueryParamsService {
    // Helper function to get a query parameter by key
    getQueryParam(param: string): string | null {
        return new URLSearchParams(window.location.search).get(param);
    }

    // Getter for the authorization token
    getAuthorizationToken(): string | null {
        return this.getQueryParam(IframeQueryParams.AUTHORIZATION_TOKEN);
    }

    // Getter for iframe mode flag
    isIframeModeEnabled(): boolean {
        return (
            this.getQueryParam(IframeQueryParams.IS_IFRAME_MODE_ENABLED) ===
            'true'
        );
    }

    // Getter for test mode flag (for student test sessions)
    isTestModeActive(): boolean {
        return (
            this.getQueryParam(IframeQueryParams.IS_TEST_MODE_ACTIVE) === 'true'
        );
    }

    // Getter for source application
    getSourceApplication(): string | null {
        return this.getQueryParam(IframeQueryParams.SOURCE_APPLICATION);
    }

    // Getter for exercise ID
    getExerciseId(): string | null {
        return this.getQueryParam(IframeQueryParams.EXERCISE_ID);
    }

    // Getter for subject ID
    getSubjectId(): string | null {
        return this.getQueryParam(IframeQueryParams.SUBJECT_ID);
    }

    // Getter for chapter ID
    getChapterId(): string | null {
        return this.getQueryParam(IframeQueryParams.CHAPTER_ID);
    }

    // Getter for topic ID
    getTopicId(): string | null {
        return this.getQueryParam(IframeQueryParams.TOPIC_ID);
    }

    // Getter for content ID
    getContentId(): string | null {
        return this.getQueryParam(IframeQueryParams.CONTENT_ID);
    }

    // Getter for section ID
    getSectionId(): string | null {
        return this.getQueryParam(IframeQueryParams.SECTION_ID);
    }

    // Getter for institute ID
    getInstituteId(): string | null {
        return this.getQueryParam(IframeQueryParams.INSTITUTE_ID);
    }
}
