import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

export function handleError(error: HttpErrorResponse) {
    let message: any = {
        message: error?.error?.message,
        status: error?.status,
    };

    switch (error?.status) {
        case 400:
            message = error?.error || 'Bad Request Error';
            break;
        case 401:
            message = error?.error || 'Unauthenticated';
            break;
        case 404:
            message = error?.error || 'Not Found Error';
            break;
        case 409:
        case 412:
            message = error?.error || 'Conflict / Precondition Failed';
            break;
        case 413:
        case 503:
            message = error?.error || 'Service Unavailable';
            break;
        case 500:
            message = error?.error || 'Internal Server Error';
            break;
        default:
            if (error?.error instanceof ErrorEvent) {
                message = `An error occurred: ${error?.error?.message}`;
            }
            break;
    }

    return throwError(message);
}

export class ErrorHandlerService {
    constructor() {}
}
