import { CommonModule } from '@angular/common';
import {
    APP_INITIALIZER,
    ErrorHandler,
    Injectable,
    NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { PpHttpModule } from '@penpencil/common';
import { environment } from 'environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ShowErrorComponent } from './common/show-error/show-error/show-error.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TokenInterceptorService } from './common/services/token-interceptor/token-interceptor.service';
import * as Sentry from '@sentry/angular-ivy';
import { MainHeaderComponent } from './common/components/main-header/main-header.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SNACKBAR_MESSAGE } from './common/services/global-constant/global-constant.service';
import { Router } from '@angular/router';

// Expose tinymce instance to the window
declare const require: any;
(window as any).tinymce = require('tinymce');

// import synchronous mathtype-tinymce5 package
require('@wiris/mathtype-tinymce5');

/*custom TranslateLoader*/
export function createTranslateLoader(http: HttpClient): TranslateLoader {
    /*return new TranslateHttpLoader(http, './assets/i18n/', '.json');*/
    return new TranslateFallbackHttpLoader(http, './assets/i18n/', '.json');
}

/*Translate fallback loader*/
export class TranslateFallbackHttpLoader implements TranslateLoader {
    private static readonly WAIT_FOR_SERVER = 2000;
    private DEFAULT_LANG: string =
        SNACKBAR_MESSAGE?.SETTING_ENGLISH_DEFAULT_LANG;
    constructor(
        private http: HttpClient,
        public prefix: string,
        public suffix: String,
        public fallbackPrefix: string = '/assets/i18n/',
        public fallbackSuffix: string = '.json'
    ) {}

    public getTranslation(lang: string): Observable<Object> {
        return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
            catchError((error) => {
                window.alert(this.DEFAULT_LANG);
                lang = 'eng';
                return this.http.get(
                    `${this.fallbackPrefix}${lang}${this.fallbackSuffix}`
                );
            })
        );
    }
}

@NgModule({
    declarations: [AppComponent, ShowErrorComponent, MainHeaderComponent],

    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        PpHttpModule.forRoot(environment.apiConfig),
        MaterialModule,
        FormsModule,
        EditorModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: 'eng',
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptorService,
            multi: true,
        },
        {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
    },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ],
    bootstrap: [AppComponent],
    exports: [MainHeaderComponent],
})
export class AppModule {}
