import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { QuestionPaperService } from 'app/common/services/question-paper/question-paper.service';
import { PROCESSING_TYPE } from '../../../services/global-constant/global-constant.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
    loaderSub!: Subscription;
    message: string = '';

    constructor(
        private loaderService: LoaderService,
        public dialog: MatDialog,
        private qpService: QuestionPaperService
    ) {}

    ngOnInit(): void {
        const message$: Observable<string> = combineLatest([
            this.loaderService.loaderMessage,
            this.qpService._processedZipFileCount$,
        ]).pipe(
            map(([loaderMsg, processedZip]) => {
                if (
                    processedZip &&
                    processedZip.type === PROCESSING_TYPE.FILE_PROCESSED &&
                    loaderMsg &&
                    loaderMsg.length > 0
                ) {
                    return `${loaderMsg} (${Math.round(processedZip.processed)}%)`;
                } else {
                    return loaderMsg;
                }
            })
        );

        this.loaderSub = message$.subscribe((msg) => {
            this.message = msg;
        });
    }

    ngOnDestroy() {
        this.loaderSub?.unsubscribe();
        this.qpService._processedZipFileCount$?.next({});
    }
}
