import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanDeactivate,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { lastValueFrom, map, Observable } from 'rxjs';

import { AfterLoginService } from './common/services/after-login/after-login.service';
import { AuthService } from './common/services/auth/auth.service';
import { GlobalService } from './common/services/global/global.service';
import { QuestionPaperService } from './common/services/question-paper/question-paper.service';
import { ShowErrorService } from './common/services/showError/show-error.service';
import { StorageService } from './common/services/storage/storage.service';
import { QueryParamsService } from './common/services/query-params/query-params.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard
    implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad
{
    random_id: string = '';
    isUnderMaintenance: boolean = false;
    constructor(
        private globalService: GlobalService,
        private router: Router,
        private _afterLoginService: AfterLoginService,
        private authService: AuthService,
        private _storageService: StorageService,
        private qpService: QuestionPaperService,
        private showErrorService: ShowErrorService,
        public queryParamService: QueryParamsService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): // @ts-ignore
    | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.isUnderMaintenance) {
            this.router.navigate(['/maintenance']);
            return false;
        }

        if (this.globalService.isAuth()) {
            const user = this.globalService.getUser();

            return user.pipe(
                map((res: any) => {
                    if (res) {
                        /* Check if user is coming from iframe mode */
                        if (this.queryParamService.isIframeModeEnabled()) {
                            this.storeDataFromSource();
                        }
                        return true;
                    } else {
                        this._storageService.clearAllLocalStorage();
                        this.router.navigate(['auth']);
                        return false;
                    }
                })
            );
        } else {
            /* Check if user is coming from iframe mode */
            if (this.queryParamService.isIframeModeEnabled()) {
                return this.validateUserInIframe().pipe(
                    map((response: any) => {
                        if (response?.success) {
                            this.storeDataFromSource();
                            this.overrideUserDetails(response);
                            return true;
                        } else {
                            this.router.navigate(['auth']);
                            return false;
                        }
                    })
                );
            } else {
                this.router.navigate(['auth']);
                return false;
            }
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.isUnderMaintenance) {
            this.router.navigate(['/maintenance']);
            return false;
        }

        return true;
    }

    canDeactivate(
        component: unknown,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return true;
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return true;
    }

    isUserCameFromAcademyAdmin(): boolean {
        return (
            this.queryParamService.getSourceApplication() ===
            this._storageService.ACADEMY_ADMIN
        );
    }

    validateUserInIframe(): Observable<HttpResponse<any>> {
        const authToken = this.queryParamService.getAuthorizationToken();

        if (authToken) {
            this._storageService.setUserToken(authToken);
        }

        return this.authService.self();
    }

    storeDataFromSource(): void {
        const sourceApp = this.queryParamService.getSourceApplication();
        this._storageService.setSourceApp(sourceApp);

        /*For PP-Admin source*/
        const isIframeModeEnabled =
            this.queryParamService.isIframeModeEnabled();
        const EXAM_CATEGORY_DATA: any = {
            exerciseId: this.queryParamService.getExerciseId(),
            contentId: this.queryParamService.getContentId(),
            subjectId: this.queryParamService.getSubjectId(),
            chapterId: this.queryParamService.getChapterId(),
            topicId: this.queryParamService.getTopicId(),
            sectionId: this.queryParamService.getSectionId(),
        };

        const isTestModeActive = this.queryParamService.isTestModeActive();

        if (isTestModeActive) {
            this._storageService.setIsTestModeActive(Boolean(isTestModeActive));
        } else {
            this._storageService.removeIsTestModeActive();
        }

        if (isIframeModeEnabled) {
            this._storageService.setIsIframeModeEnabled(
                Boolean(isIframeModeEnabled)
            );
        }
        this._storageService.setAdminImportedData(EXAM_CATEGORY_DATA);

        /*For PW-Academy source*/
        const instituteId = this.queryParamService.getInstituteId();
        const userRole = this.queryParamService.getQueryParam('userRoleId');

        if (this.isUserCameFromAcademyAdmin()) {
            this._storageService.setInstituteID(instituteId);
            this._storageService.setAcademyUserRoleId(userRole || '');
        }
    }

    async overrideUserDetails(response: any): Promise<void> {
        const authToken = this.queryParamService.getAuthorizationToken();
        this._afterLoginService.afterLoginProcess(response, authToken);
    }
}
