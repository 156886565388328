export const EMAIL_PATTERN =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const PHONE_NUMBER_PATTERN = /^[6-9]\d{9}$/;

export const ALPHA_NUMERIC_PATTERN = /^[a-zA-Z0-9_.\s-\d+\.?\d]*$/;

export const NUMERIC_PATTERN = /^[0-9]*$/;

export const HTML_TAGS_REMOVAL_PATTERN = /(<([^>]+)>)/gi;

export const HTML_IMG_TAG_REMOVAL_PATTERN = /<img[^>]*>/g;

export const ONLY_CONTAINS_INTEGERS_FLOAT_NUMERICAL_PATTERN =
    /^0$|^-?[1-9]\d*(\.\d+)?$/;
export const ALLOW_EVERYTHING_EXCEPT_RESTRICTED_PATTERN = /^[^\\/:*?"<>|]*$/u;

export const INTEGER_PATTERN = /^-?\d+$/;

export const POSITIVE_INTEGER_PATTERN = /^[1-9]\d*$/;

export const EMAIL_PATTERN_PW_DOMAIN =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@pw\.live$/i;

export const DRIVE_LINK_REGEX =
    /^https:\/\/drive\.google\.com\/file\/d\/[A-Za-z0-9_-]+/;

export const SECURE_VIDEO_URL_REGEX =
    /^(https?:\/\/)?[\w.-]+(\/[\w.-]*)+\/[\w.-]+\.mpd$/;

export const YOU_TUBE_VIDEO_URL_REGEX =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
