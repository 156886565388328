import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { LoaderComponent } from '../../components/loader/loader/loader.component';
import { delay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    loadingCount = 0;
    dialogRef: MatDialogRef<LoaderComponent> | null = null;
    loaderMessage = new BehaviorSubject<string>('');
    messageSubscription: Subscription | null = null;

    constructor(
        private dialog: MatDialog,
        private overlay: Overlay
    ) {}

    async loadData(message: string = '') {
        this.loadingCount++;
        this.loaderMessage.next(message);

        if (this.loadingCount === 1) {
            this.openDialog();
        }
    }

    async unloadData(message: string = '') {
        if (this.loadingCount > 0) {
            this.loadingCount--;
            this.loaderMessage.next(message);
            if (this.loadingCount === 0) {
                this.closeDialog();
            }
        }
    }

    private openDialog(): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;
        dialogConfig.disableClose = true;
        dialogConfig.scrollStrategy =
            this.overlay.scrollStrategies.reposition();
        dialogConfig.panelClass = 'custom-dialog-container';

        this.dialogRef = this.dialog.open(LoaderComponent, dialogConfig);

        this.messageSubscription = this.loaderMessage.subscribe((msg) => {
            if (this.dialogRef && this.dialogRef.componentInstance) {
                this.dialogRef.componentInstance.message = msg;
            }
        });
    }

    private closeDialog(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
            this.messageSubscription = null;
        }
        this.loaderMessage.next('');
    }
}
