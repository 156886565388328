import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalService } from '../global/global.service';

@Injectable({
    providedIn: 'root',
})
export class ShowErrorService {
    public errorMessage = new BehaviorSubject<any>({ status: '', message: '' });

    constructor(private globalService: GlobalService) {}

    async showError(err: any) {
        const errorMsg = this.extractErrorMessage(err);
        await this.presentAlert(errorMsg);
    }

    private extractErrorMessage(err: any) {
        let message: string;

        if (err?.error?.message) {
            message = err.error.message;
        } else if (err?.error?.error?.message) {
            message = err.error.error.message;
        } else if (err?.errorMessage) {
            message = err.errorMessage;
        } else if (err?.message) {
            message = err.message;
        } else if (
            err?.error_messages?.length &&
            typeof err.error_messages[0]?.data?.errorMessage === 'string'
        ) {
            message = err.error_messages[0].data.errorMessage;
        } else if (!navigator.onLine) {
            message = `There is no internet connection. Please check your connection`;
        } else {
            message =
                'Oops! Something went wrong. Please try refreshing the page. If the issue persists, please report and try again later.';
        }

        const displayMessage = {
            status: `${err?.error?.status || err?.statusCode || ''}`,
            message: `${message || ''}`,
        };
        return displayMessage;
    }

    async presentAlert(errMessage: any) {
        this.globalService.showSnackBar(`${errMessage?.message}`, 'Ok');
    }
}
